<!-- 秒杀菜单 -->
<template>
    <div>
        <goodListTemp :productType="3"/>
    </div>
</template>

<script>
import goodListTemp from '@/components/goodListTemp'
export default {
    name: 'MiaoSha',
    components: { goodListTemp },

    data() {
        return {
            
        };
    },

    created() {
        
    },

    methods: {

    }
}
</script>
<style lang='scss' scoped>

</style>